import React from 'react';

import styled from 'styled-components';
import { logout } from './AuthProvider';

const Button = styled.button`
  padding: 12px 24px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #d32f2f;
  }
`;

const LogoutButton = () => {
  return (
    <Button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Logout
    </Button>
  );
};

export default LogoutButton;

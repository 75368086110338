import { LOGIN_BASE_URL } from "../../config/config";


export const AuthProvider = (props) => {
    return (<div>{props.children}</div>);
};

export const HandleRedirect = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const idToken = searchParams.get("id_token");
    const refreshToken = searchParams.get("refresh_token");
    
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('refresh_token', refreshToken);

    if (isLoggedIn()) {
        window.location.href = window.location.origin + "/dashboard";
    }

    else {
        window.location.href= LOGIN_BASE_URL + "/login?redirect_uri=" + window.location.origin + "/callback";
    }
}

export const isLoggedIn = () => !!localStorage.getItem("id_token");
export const logout = () => {
    localStorage.removeItem('id_token');
    window.location.href = window.location.origin;
}

export default AuthProvider;
import React from "react";
import styled from 'styled-components';
import { LOGIN_BASE_URL } from "../../config/config";

const Button = styled.button`
  background-color: #10aca1;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0e938b;
  }
`;

const LoginButton = () => {
  const handleLogin = () => {
    window.location.href = LOGIN_BASE_URL + "/login?redirect_uri=" + window.location.origin + "/callback";
  };

  return <Button onClick={handleLogin}>Login</Button>;
};

export default LoginButton;
